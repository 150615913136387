import { theme } from "@/GlobalStyles";
import styled from "@emotion/styled";
import { mq } from "styles/media-query";
import ContactBg from "../../../../images/contact-bg.png";

export const ContactMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin-top: -140px;

  ${mq["md"]} {
    justify-content: center;
    margin-top: -100px;
    flex-wrap: wrap;
  }
`;

export const ContactContainer = styled.div`
  max-width: 476px;
  width: 100%;
  margin: 32px;

  ${mq["lg"]} {
    margin: 10px;
  }
`;

export const HeadingWrapper = styled.div`
  display: block;

  ${mq["md"]} {
    display: none;
  }
`;
export const MailBox = styled.div`
  margin-left: 6px;
  margin-bottom: -5px;
  ${mq["md"]} {
    margin-left: 17px;
  }
`;

export const ContactInformation = styled.div`
  background-color: ${theme.colors.offWhite};
  margin-top: 25px;
  padding: 60px;
  ${mq["lg"]} {
    padding: 15px;
  }

  ${mq["md"]} {
    padding: 20px;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${mq["md"]} {
    display: block;
    text-align: center;
  }
`;

export const ContactFormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(${ContactBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 104px 32px 64px 32px;
  width: 100%;
  max-width: 720px;
  height: max-content;

  ${mq["lg"]} {
    height: max-content;
    padding: 64px 30px;
    align-items: center;
  }

  ${mq["md"]} {
    padding: 64px 10px;
    width: 100%;
    background-position: center;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 26px;

  ${mq["md"]} {
    justify-content: center;
  }
`;

export const BottomNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 54px;

  ${mq["lg"]} {
    justify-content: center;
  }
`;
