import React from "react";
import { ContactUsPageContainer } from "./contact-us";
import FirstTime from "./components/first-time";
import GetInTouchAlternative from "./components/get-in-touch-alternative";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";

const ContactUsPage = () => {
  return (
    <ContactUsPageContainer>
      <AlternativeHeroBanner
        PageName="Contact Us"
        NavigateFrom="Home"
        NavigateTo="Contact Us"
        IntroHeader="We got you covered."
        introPara="From dental care to any questions, we are here for you."
        BgHero="contact-us-hero.jpg"
      />
      <GetInTouchAlternative />
      <FirstTime />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3220.3199821214534!2d-86.51216459999999!3d36.1830991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886415c4f8fa68e5%3A0xf86d0351d79ed820!2s820%20N%20Mt%20Juliet%20Rd%2C%20Mt.%20Juliet%2C%20TN%2037122!5e0!3m2!1sen!2sus!4v1666658570462!5m2!1sen!2sus"
        width="100%"
        height="650"
        style={{ border: "none", maxWidth: "1440px" }}
        aria-hidden="false"
        title="Pearl Pediatric"
        loading="lazy"
      />
    </ContactUsPageContainer>
  );
};

export default ContactUsPage;
