import React from "react";
import ArrowButton from "@/common/arrow-button";
import Image from "@/common/image";
import { theme } from "@/GlobalStyles";
import { Heading, Paragraph } from "styles/typography";
import {
  ContactPicContainer,
  ContactPicInfo,
  ContentWrapper,
} from "./first-time";

const FirstTime = () => {
  return (
    <ContactPicContainer>
      <ContactPicInfo>
        <ContentWrapper>
          <Heading letterSpacing="normal">FIRST TIME?</Heading>
          <Paragraph margin="32px 0px ">
            Let’s make things easy before you even step into our office.
          </Paragraph>
        </ContentWrapper>
      </ContactPicInfo>
    </ContactPicContainer>
  );
};

export default FirstTime;
