import React, { useState } from "react";
import ArrowButton from "@/common/arrow-button";
import Button from "@/common/button";
import CustomInput from "@/common/custom-input";
import { theme } from "@/GlobalStyles";
import { Heading, Paragraph, SubHeading } from "styles/typography";
import Directions from "svgs/directions";
import Phone from "svgs/phone";
import { ReactComponent as Mail } from "svgs/email.svg";
import {
  AddressWrapper,
  BottomNavigation,
  ContactContainer,
  ContactFormContainer,
  ContactInformation,
  ContactMain,
  FieldsWrapper,
  HeadingWrapper,
  MailBox,
} from "./get-in-touch-alternative";
import { navigate } from "gatsby";

const GetInTouchAlternative = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (e: any) => {
    setSubmitting(true);
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.target));

    await fetch(`https://api.${window.location.host}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    e.target.reset();
    setSubmitting(false);
    setSubmitted(true);
    navigate("/thankyou/");
  };

  return (
    <ContactMain>
      <ContactContainer>
        <Heading>GET IN TOUCH</Heading>
        <ContactInformation>
          <HeadingWrapper>
            <SubHeading gradient>Address</SubHeading>
          </HeadingWrapper>
          <AddressWrapper>
            <Directions />
            <a
              target="_blank"
              href="https://maps.app.goo.gl/3mB7SiLyGk7qK5XX8?g_st=ic"
              rel="noreferrer"
            >
              820 N Mt. Juliet Rd, Mount Juliet, TN 37122
            </a>
          </AddressWrapper>
          <HeadingWrapper>
            <SubHeading gradient>Call us</SubHeading>
          </HeadingWrapper>
          <AddressWrapper>
            <Phone color={theme.colors.darkGolden} width="40px" />
            <a href="tel:615 301-8660">(615) 301-8660</a>
          </AddressWrapper>
          <HeadingWrapper>
            <SubHeading gradient>E-mail us</SubHeading>
          </HeadingWrapper>
          <AddressWrapper>
            <MailBox>
              <Mail width="45px" />
            </MailBox>{" "}
            <a href="mailto:hello@pearlpdo.com">hello@pearlpdo.com</a>
          </AddressWrapper>
        </ContactInformation>
      </ContactContainer>

      <ContactFormContainer>
        <Heading
          color={theme.colors.black}
          maxWidth="300px"
          letterSpacing="normal"
        >
          Send us a message
        </Heading>
        <form onSubmit={onSubmit}>
          <FieldsWrapper>
            <CustomInput
              required
              borderlabel="2px solid "
              name="name"
              placeholder="Your name"
              width="280px"
              height="90px"
            />
            <CustomInput
              required
              borderlabel="2px solid "
              name="email"
              placeholder="Email"
              width="280px"
              height="90px"
              type="email"
            />
            <CustomInput
              required
              borderlabel="2px solid "
              name="phone"
              placeholder="Primary phone"
              width="280px"
              height="90px"
            />
            <CustomInput
              required
              name="message"
              placeholder="Message for the team"
              borderlabelarea="2px solid"
              multiline={true}
              width="280px"
              height="400px"
            />
          </FieldsWrapper>
          <BottomNavigation>
            <a href="tel:615 301-8660">
              <ArrowButton
                title="CALL US INSTEAD"
                titleColor={theme.colors.black}
                border={`2px solid ${theme.colors.black}`}
                direction="row-reverse"
                rotate="rotate(45deg)"
                mobileDirection="row-reverse"
                arrowMargin="20px 0px"
              />
            </a>
            {submitted ? (
              <p>Thank you! Someone will be in touch soon.</p>
            ) : (
              <Button
                type="submit"
                title={submitting ? "SENDING..." : "SEND US A MESSAGE"}
                bgColor={theme.colors.black}
                color={theme.colors.white}
              />
            )}
          </BottomNavigation>
        </form>
      </ContactFormContainer>
    </ContactMain>
  );
};

export default GetInTouchAlternative;
