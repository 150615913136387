import { theme } from "@/GlobalStyles";
import styled from "@emotion/styled";
import FirstTime from "../../../../images/first-time.jpg";

export const ContactPicContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 1000px;
  background-position: bottom;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(${FirstTime});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ContactPicInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(32px);
  flex-wrap: wrap;
  padding: 64px 0px 16px 0px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  max-width: 283px;
`;
